import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate() {
        if (!this.authService.isUserAuth()) {
            return true;
        } else {
            this.router.navigateByUrl('/noticing/list');
            // this.router.navigateByUrl('/dashboard/v2');
            return false;
        }
    }
}
