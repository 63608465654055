<div class="app-footer">
	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">        
		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div>
				<p class="m-0">&copy; {{ year }} ProsocialWorld</p>
				<p class="m-0">All rights reserved</p>
			</div>
		</div>
	</div>
</div>