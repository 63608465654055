<div class="main-header">
    <div class="logo">
        <a href="./"><img src="./assets/images/logo.png" alt=""></a>
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
        <!-- <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i>
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Gear text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid">
                    <a href="#"><i class="i-Shop-4"></i> Home</a>
                    <a href="#"><i class="i-Library"></i> UI Kits</a>
                    <a href="#"><i class="i-Drop"></i> Apps</a>
                    <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                    <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                    <a href="#"><i class="i-Ambulance"></i> Support</a>
                </div>
            </div>
        </div> -->

        <div ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container">
                <span class="badge badge-primary">{{notifications.length}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
            </div>
            <div *ngIf="notifications.length > 0" ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item d-flex" *ngFor="let item of notifications">
                    <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} mr-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <a href="{{item.link}}" target="_blank">
                            <p class="m-0 d-flex align-items-center">
                                <span>{{item.title | excerpt:25}}</span>
                                <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span>
                                <span class="flex-grow-1"></span>
                            </p>
                            <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <ng-template [ngIf]="userData">
                <img [src]="userData.avatar | secureUrl" id="userDropdown" ngbDropdownToggle alt="">
    
                <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                    <button class="dropdown-item" *ngIf="userData.fullName" (click)="onOpenNing()">{{ userData.fullName }}</button>
                    <button class="dropdown-item">Account settings</button>
                    <button class="dropdown-item" (click)="onSignOut()">Sign out</button>
                </div>
            </ng-template>
        </div>

    </div>

</div>