import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { DatabaseService } from 'src/app/shared/services/database.service';
import { Router } from "@angular/router";

@Component({
    selector: 'app-header-sidebar-large',
    templateUrl: './header-sidebar-large.component.html',
    styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    notifications: any[];
    userData: any = null;

    constructor(
        private navService: NavigationService,
        public searchService: SearchService,
        private authService: AuthService,
        private firebaseDatabase: AngularFireDatabase, private cdRef: ChangeDetectorRef,
        private store: LocalStoreService, private databaseService: DatabaseService,
        private router: Router
    ) {

        // this.notifications = [
        //     {
        //         icon: 'i-Speach-Bubble-6',
        //         title: 'New message',
        //         badge: '3',
        //         text: 'James: Hey! are you busy?',
        //         time: new Date(),
        //         status: 'primary',
        //         link: '/chat'
        //     },
        //     {
        //         icon: 'i-Receipt-3',
        //         title: 'New order received',
        //         badge: '$4036',
        //         text: '1 Headphone, 3 iPhone x',
        //         time: new Date('11/11/2018'),
        //         status: 'success',
        //         link: '/tables/full'
        //     },
        //     {
        //         icon: 'i-Empty-Box',
        //         title: 'Product out of stock',
        //         text: 'Headphone E67, R98, XL90, Q77',
        //         time: new Date('11/10/2018'),
        //         status: 'danger',
        //         link: '/tables/list'
        //     },
        //     {
        //         icon: 'i-Data-Power',
        //         title: 'Server up!',
        //         text: 'Server rebooted successfully',
        //         time: new Date('11/08/2018'),
        //         status: 'success',
        //         link: '/dashboard/v2'
        //     },
        //     {
        //         icon: 'i-Data-Block',
        //         title: 'Server down!',
        //         badge: 'Resolved',
        //         text: 'Region 1: Server crashed!',
        //         time: new Date('11/06/2018'),
        //         status: 'danger',
        //         link: '/dashboard/v3'
        //     }
        // ];

        this.notifications = [];

        this.authService.getUserData()
            .subscribe((data: any) => {
                this.userData = data;

                var memberId = this.userData.url.split("/")[4];
                var messagesRef = this.firebaseDatabase.database.ref('messages/' + memberId);
        
                messagesRef.on('value', function(snapshot) {
                    let notifications = [];
                    snapshot.forEach(function (aMessage) {
                        let notification = {
                            icon: 'i-Speach-Bubble-6',
                            title: aMessage.val().title,
                            badge: null,
                            text: aMessage.val().message,
                            status: 'primary',
                            link: aMessage.val().link
                        }
                        notifications.push(notification);
                    });
                    //console.log(notifications);
                    this.setNotifications(notifications);
                    this.cdRef.detectChanges();
                }.bind(this));
        
            });
    
    }

    ngOnInit() {
    }

    goToUrl(url) {
        this.router.navigateByUrl(url);
    }

    setNotifications(notifications) {
        this.notifications = notifications;
    }

    toggelSidebar() {
        const state = this.navService.sidebarState;
        if (state.childnavOpen && state.sidenavOpen) {
            return state.childnavOpen = false;
        }
        if (!state.childnavOpen && state.sidenavOpen) {
            return state.sidenavOpen = false;
        }
        // item has child items
        if (!state.sidenavOpen && !state.childnavOpen
            && this.navService.selectedItem.type === 'dropDown') {
            state.sidenavOpen = true;
            setTimeout(() => {
                state.childnavOpen = true;
            }, 50);
        }
        // item has no child items
        if (!state.sidenavOpen && !state.childnavOpen) {
            state.sidenavOpen = true;
        }
    }

    onSignOut() {
        this.authService.signOut();
        this.router.navigateByUrl("/sessions/signin");
    }

    
    onOpenNing() {

        if (this.userData && this.userData.url) {
            window.open(this.userData.url, "_blank");
        }

    }

}
