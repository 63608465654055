import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
    selector: 'btn-sort',
    templateUrl: './btn-sort.component.html',
    styleUrls: ['./btn-sort.component.scss']
})
export class BtnSortComponent {
    
    @Input('questionNumber') questionNumber: number = null;
    @Input('icon') icon: string = null;
    @Input('sortingCriteria') sortingCriteria = null;
    @Output() onChangeOrder: EventEmitter<any> = new EventEmitter<any>();
    
    public active = false;
    public order: string = null;


    ngOnChanges(changes: SimpleChanges) {

        // Change change sortingCriteria, need to figure out
        // if this component is active and keep its current sort option (if active)
        if (changes.sortingCriteria) {
            this.active = (changes.sortingCriteria.currentValue.criteria === this.icon);
            this.order = (this.active)
                            ? changes.sortingCriteria.currentValue.order
                            : null;
        }
    }


    /**
     * Handle when click on button options (arrow or icon)
     */
    public onClick() {

        // Change current order to the opposite
        // or set 'desc' if no order was defined (first time it is clicked)
        this.order = (!this.order || this.order === 'asc') ? 'desc' : 'asc';
        this.active = true;
        const data = {
            order: this.order,
            questionNumber: this.questionNumber,
            criteria: this.icon
        }

        // Output data
        this.onChangeOrder.emit(data);
    }


}
