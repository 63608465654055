import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { encode } from 'punycode';
import { Base64 } from 'js-base64';
import { LocalStoreService } from "./local-store.service";


@Injectable({
  providedIn: 'root'
})
export class NingService {

  networkURL = "prosocialWorld";
  oauth_consumer_key = 'c1eb7fd4-415d-4801-92a5-c64106b1d488';
  oauth_signature = 'f3960542-5052-4ab1-ba13-fc56814ff87e%26'; // Consumer Secret + %26 + Signature
  url = `https://external.ningapis.com/xn/rest/${this.networkURL}/2.0/`;
  login_params = `oauth_signature_method=PLAINTEXT&oauth_consumer_key=${this.oauth_consumer_key}&oauth_signature=${this.oauth_signature}`;

  constructor(private http: HttpClient, private store: LocalStoreService) { }

  getLoggedHeaders (oauth_token, oauthTokenSecret) {
    return {
      'Authorization': `OAuth oauth_signature_method="PLAINTEXT",oauth_consumer_key="${this.oauth_consumer_key}",oauth_token="${oauth_token}",oauth_signature="${this.oauth_signature}${oauthTokenSecret}"`
    }; 
  }

  getLoginHeaders (credentials) {
    let token = Base64.encode(credentials.email+':'+credentials.password);
    return {
      'Authorization': `Basic ${token}`
    }; 
  }

  login(credentials) {
    let headers = this.getLoginHeaders(credentials);
    let loginUrl = `https://external.ningapis.com/xn/rest/${this.networkURL}/1.0/`;

    return new Promise((resolve, reject) => {
      fetch(`${loginUrl}Token?xn_pretty=true&${this.login_params}`, {
        method: 'POST',
        headers: headers
      })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
    });
  }

  getUser() {
    let author = this.store.getItem("author");
    let oauthToken = this.store.getItem("oauthToken");
    let oauthTokenSecret = this.store.getItem("oauthTokenSecret");

    let headers = this.getLoggedHeaders(oauthToken, oauthTokenSecret);

    return new Promise((resolve, reject) => {
      fetch(`${this.url}User?xn_pretty=true&author=${author}&fields=url,title,author,author.fullName,author.url,author.iconUrl,fullName,email,featuredDate,profileQuestions,description`, {
        method: 'GET',
        headers: headers
      })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
    });
  }

  getGroups() {
    let author = this.store.getItem("author");
    let oauthToken = this.store.getItem("oauthToken");
    let oauthTokenSecret = this.store.getItem("oauthTokenSecret");
    let headers = this.getLoggedHeaders(oauthToken, oauthTokenSecret);

    return new Promise((resolve, reject) => {
      fetch(`${this.url}Group/recent?fields=title,description,imageUrl&count=100`, {
        method: 'GET',
        headers: headers
      })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
    });
  }

  getMyGroups() {
    let author = this.store.getItem("author");
    let oauthToken = this.store.getItem("oauthToken");
    let oauthTokenSecret = this.store.getItem("oauthTokenSecret");
    let headers = this.getLoggedHeaders(oauthToken, oauthTokenSecret);

    return new Promise((resolve, reject) => {
      fetch(`${this.url}Group/recent?fields=title,description,imageUrl&count=100&author=${author}`, {
        method: 'GET',
        headers: headers
      })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
    });
  }

  sendMessage(aMemberId, aMessage) {
    let author = this.store.getItem("author");
    let oauthToken = this.store.getItem("oauthToken");
    let oauthTokenSecret = this.store.getItem("oauthTokenSecret");
    let headers = this.getLoggedHeaders(oauthToken, oauthTokenSecret);
    let data = {
      recipients: ['0lp9fz0a2plrt','0lvjgid0s9vdn'],
      subject: "prueba",
      body: "prueba boy",
      email: true
    };

    return new Promise((resolve, reject) => {

      fetch(`${this.url}Message`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(data => resolve(data))
      .catch(err => reject(err));
    });
  }

}