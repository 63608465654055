import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of, Subject, Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { NingService } from "./ning.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    
    // Only for demo purpose
    data: any = null;

    constructor(private store: LocalStoreService, private router: Router, private ningService: NingService) {
    }


    /**
     * Get user data from firebase
     */
    public getUserData(): Observable<any> {
        const subject = new Subject<any>();

        this.ningService.getUser().then(
            (data: any) => {
                if (data) {
                    const user: any = {
                        fullName: (data.entry && data.entry[0]) ? data.entry[0].fullName : null,
                        email: (data.entry && data.entry[0]) ? data.entry[0].email : null,
                        avatar: (data.entry[0] && data.entry[0].title && data.resources) ? data.resources[data.entry[0].title].iconUrl : null,
                        url: (data.entry && data.entry[0]) ? data.entry[0].url : null,
                        ningId: (data.entry && data.entry[0]) ? data.entry[0].url.split("/")[4] : null,
                    }
                    this.store.setItem("user", user);
                    subject.next(user);
                }
                else {
                    subject.next(null);
                }
            });

        return subject.asObservable();
    }


    /**
     * Sign in using Ning service
     */
    public signIn(credentials) {
        const subject = new Subject<any>();

        this.ningService.login(credentials)
            .then((data: any) => {
                if (data) {
                    if (data.success) {
                        this.store.setItem("demo_login_status", true);
                        this.store.setItem("author", data.entry.author);
                        this.store.setItem("oauthToken", data.entry.oauthToken);
                        this.store.setItem("oauthTokenSecret", data.entry.oauthTokenSecret);
                        subject.next(data);
                    } else {
                        subject.error({ msg: data.reason || 'Username or password are invalid' });
                    }
                }
            })
            .catch((err: any) => {
                subject.error(err);
            });
        return subject.asObservable();
    }


    /**
     * Sign out user
     */
    public signOut() {
        this.store.setItem("demo_login_status", false);
        this.store.setItem("author", null);
        this.store.setItem("oauthToken", null);
        this.store.setItem("oauthTokenSecret", null);
    }


    /**
     * Return true if user is authenticated
     */
    public isUserAuth() {
        if (this.store.getItem('author')
            && this.store.getItem('oauthToken')
            && this.store.getItem('oauthTokenSecret')) {
                return true;
        }
        else {
            this.signOut();
            return false;
        }
    }

}
