import { Injectable } from '@angular/core';
import { LocalStoreService } from "./local-store.service";
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {

    constructor(private store: LocalStoreService, private firebaseDatabase: AngularFireDatabase) { }

    public getNingUserId(user) {
        if (user == null) {
            console.error("User not saved in storage")
            return null;
        }

        let url = user.url;
        if (url == null) {
            console.error("User has no url")
            return null;
        }

        return url.split("/")[4];
    }

    public getMyNoticings() {
        return new Promise((resolve, reject) => {

            let ningUserId = this.getNingUserId(this.store.getItem("user"));

            if (ningUserId == null) {
                reject("NingUserId is null");
            }

            var db = this.firebaseDatabase.database;
            var noticingConfigs = db.ref("noticing/noticing-config");
            var myGroupsRef = db.ref('users/' + ningUserId + '/myGroups');

            let result = {};

            myGroupsRef.once("value", function (data) {
                let myGroupIds = [];

                data.forEach(function (anIdGroup) {
                    myGroupIds.push(anIdGroup.val());
                });

                noticingConfigs.once('value', (snap) => {
                    snap.forEach(function (config) {
                        let configValue = config.val();
                        if (!configValue['status'] || configValue['status'] !== 'deleted') {
                            // TODO: replace this string for a global constant
                            if (myGroupIds.includes(configValue.owner.id)) {
                                result[`${config.key}`] = config.val();
                            }
                        }
                    });
                }).catch(err => reject(err));

                setTimeout(function () {
                    resolve(result);
                }, 1000);
            });
        });
    }

    public getNoticing(id) {
        return new Promise((resolve, reject) => {

            let ningUserId = this.getNingUserId(this.store.getItem("user"));

            if (ningUserId == null) {
                reject("NingUserId is null");
            }

            var db = this.firebaseDatabase.database;
            var noticingConfig = db.ref("noticing/noticing-config");
            var noticingData = db.ref("noticing/noticing-data");

            let result = {};

            noticingConfig.child(id).once('value', (snap) => {
                result['config'] = snap.val();
            }).catch(err => reject(err));

            noticingData.child(id).once('value', (snap) => {
                result['data'] = snap.val();
            }).catch(err => reject(err));

            setTimeout(function () {
                resolve(result);
            }, 1000);
        });
    }

    public getMyAdminGroups() {
        return new Promise<Object[]>((resolve, reject) => {

            let ningUserId = this.getNingUserId(this.store.getItem("user"));

            if (ningUserId == null) {
                reject("NingUserId is null");
            }

            var db = this.firebaseDatabase.database;
            var groups = db.ref("groups");
            var adminGroupsRef = db.ref('users/' + ningUserId + '/adminGroups');

            let result: Object[] = [];

            adminGroupsRef.once("value", function (data) {
                data.forEach(function (anIDGroup) {
                    let idGroup = anIDGroup.val();
                    let childRef = groups.child(idGroup);

                    childRef.once('value', (snap) => {
                        result.push(snap.val());
                    }).catch(err => reject(err));
                });
            }).catch(err => reject(err));

            resolve(result);
        });
    }

    public getMyGroups() {
        return new Promise<Object[]>((resolve, reject) => {

            let ningUserId = this.getNingUserId(this.store.getItem("user"));

            if (ningUserId == null) {
                reject("NingUserId is null");
            }

            var db = this.firebaseDatabase.database;
            var groups = db.ref("groups");
            var myGroupsRef = db.ref('users/' + ningUserId + '/myGroups');

            let result = [];

            myGroupsRef.once("value", function (data) {
                data.forEach(function (anIDGroup) {
                    let idGroup = anIDGroup.val();
                    let childRef = groups.child(idGroup);

                    childRef.once('value', (snap) => {
                        result.push(snap.val());
                    }).catch(err => reject(err));
                });
            }).catch(err => reject(err));

            resolve(result);
        });
    }

    public getGroupMembers(aGroupId) {
        return new Promise<Object[]>((resolve, reject) => {

            if (aGroupId == null) {
                return [];
            }

            if (aGroupId == null) {
                reject("Group Id is empty");
            }

            var db = this.firebaseDatabase.database;
            var users = db.ref("users");
            var groupMembersRef = db.ref('groups/' + aGroupId + '/members');

            let result: Object[] = [];

            groupMembersRef.once("value", function (data) {
                data.forEach(function (aMember) {

                    let childRef = users.child(aMember.val());

                    childRef.once('value', (snap) => {
                        result.push(snap.val());
                    }).catch(err => reject(err));
                });

            }).catch(err => reject(err));

            resolve(result);
        });
    }

    public getGroupAdmins(aGroupId) {
        return new Promise<Object[]>((resolve, reject) => {

            if (aGroupId == null) {
                reject("Group Id is empty");
            }

            var db = this.firebaseDatabase.database;
            var groups = db.ref("users");
            var groupMembersRef = db.ref('groups/' + aGroupId + '/admins');

            let result = [];

            groupMembersRef.once("value", function (data) {
                data.forEach(function (anAdmin) {

                    let childRef = groups.child(anAdmin.val());

                    childRef.once('value', (snap) => {
                        result.push(snap.val());
                    }).catch(err => reject(err));
                });

            }).catch(err => reject(err));

            resolve(result);
        });
    }

    public getGroup(aGroupId) {
        return new Promise<Object[]>((resolve, reject) => {

            if (aGroupId == null) {
                reject("Group Id is empty");
            }

            var db = this.firebaseDatabase.database;
            var groupRef = db.ref('groups/' + aGroupId);

            groupRef.once("value", function (data) {
                resolve(data.val());

            }).catch(err => reject(err));

        });
    }

    public getUser(aUserId): Promise<any> {
        return new Promise<Object[]>((resolve, reject) => {

            if (aUserId == null) {
                reject("User Id is empty");
            }

            var db = this.firebaseDatabase.database;
            var usersRef = db.ref('users/' + aUserId);

            usersRef.once("value", function (data) {
                const user = data.val();
                
                if (user) {

                    // Set faciitator flag
                    user.isFacilitator = (user.adminGroups && Array.isArray(user.adminGroups) && user.adminGroups.length > 0);
                }
                resolve(user);

            }).catch(err => reject(err));

        });
    }

    public updateNoticingTool(noticingId, noticingTool): Promise<any> {
        var db = this.firebaseDatabase.database;

        var updates = {};
        updates['noticing/noticing-data/' + noticingId] = noticingTool.data;

        return db.ref().update(updates);
    }

    public updateNoticingToolConfig(noticingId, noticingToolConfig): Promise<any> {
        var db = this.firebaseDatabase.database;

        var updates = {};
        updates['noticing/noticing-config/' + noticingId] = noticingToolConfig;

        return db.ref().update(updates);
    }

    public saveMessage(aMemberId, aMessage) {
        var db = this.firebaseDatabase.database;
        var memberMessagesRef = db.ref('messages/' + aMemberId);

        memberMessagesRef.push(aMessage);

    }
}
